import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Sidebar from '../components/Sidebar';
import ModalAlert from '../components/ModalAlert';
import CenteredLoading from '../components/CenteredLoading';
import ProductSubtitle from '../components/ProductSubtitle';
import DynamicComponent from '../components/DynamicComponent';
import ImageUploader from '../components/ImageUploader';
import * as Utils from '../utils/utils';
import ApiService from '../utils/ApiService';
import { useAuth } from '../contexts/AuthContext';

export default function Map() {
    const [products, setProducts] = useState(null);
    const [productIndex, setProductIndex] = useState(null);
    const [dataSets, setDataSets] = useState(null);
    const [dataIndex, setDataIndex] = useState(null);
    const [fields, setFields] = useState(null);
    const [images, setImages] = useState(null);
    const [edit, setEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const { displaySetting } = useAuth();

    useEffect(() => {
        ApiService.get('/unmapped_products').then((response) => {
            setProducts(response);
            setProductIndex(0);
        }).catch((error) => {
            setAlertMessage(error.message);
        });
    }, []);

    const resetFields = () => {
        setFields(null);
        setImages(null);
        setDataSets(null);
        setDataIndex(null);
    }

    const updateDataset = () => {
        if (products !== null && productIndex !== null && productIndex < products.length) {
            ApiService.get('/available_datasets', {'product_id': products[productIndex].id}).then((response) => {
                setDataSets(response);
                setDataIndex(0);
            }).catch((error) => {
                setAlertMessage(error.message);
            });
        }
    }

    useEffect(() => {
        resetFields();
        updateDataset();
    }, [productIndex, products]);

    useEffect(() => {
        if (images !== null && dataSets !== null && dataIndex !== null && dataSets.length > 0 && images !== dataSets[dataIndex].images) {
            setEdit(true);
        }
    }, [images]);

    useEffect(() => {
        setFields(dataSets !== null && dataIndex !== null && dataSets.length > 0 ? dataSets[dataIndex].fields : null);
        setImages(dataSets !== null && dataIndex !== null && dataSets.length > 0 ? dataSets[dataIndex].images : null);
    }, [dataIndex]);

    useEffect(() => {
        if (edit) {
            let newFields = {...fields}
            for (const field in newFields) {
                newFields[field].can_be_edited = true;
            }
            setFields(newFields);
        }
    }, [edit]);

    useEffect(() => {
        window.document.title = "Mapear | " + displaySetting('site_name');
    }, []);

    const handleDeleteDataset = () => {
        setDialogOpen(false);
        ApiService.post('/trash_dataset', {'dataset_id': dataSets[dataIndex].product_data_id}).then((_) => {
            document.getElementById("product-information").scrollIntoView();
            if (dataSets.length === 1) {
                let newProducts = [...products];
                newProducts.splice(productIndex, 1);
                setProducts(newProducts);
            } else {
                let newDatasets = [...dataSets];
                newDatasets.splice(dataIndex, 1);
                setDataIndex(0);
                setDataSets(newDatasets);
            }
        }).catch((error) => {
            setAlertMessage(error.message);
        });
    }

    const closeDialog = () => {
        setDialogOpen(false);
    }

    const setMapping = () => {
        if (!edit) {
            ApiService.post('/set_mapping', {
                'product_id': products[productIndex].id,
                'dataset': fields,
                'dataset_id': dataSets[dataIndex].product_data_id
            }).then((_) => {
                setProductIndex(productIndex + 1);
            }).catch((error) => {
                setAlertMessage(error.message);
            });
        } else {
            saveChanges();
        }
    }

    const setErrorMsgSave = (msg) => {
        if (msg.length > 0) {
            setAlertMessage(msg);
        } else {
            setAlertMessage('Erro ao salvar o produto');
        }
    };

    async function getUpdatedImages(uploadedImages) {
        if (uploadedImages) {
            let newImages = [];
            let i = 0;
            await images.forEach((image) => {
                if (image instanceof File) {
                    newImages.push({ filepath: uploadedImages[i] });
                    i++;
                } else {
                    newImages.push(image);
                }
            });
            return newImages;
        }
        return null;
    }

    const saveChanges = async () => {
        const saveProduct = async () => {
            if (products[productIndex].fields === fields) {
                return;
            }

            const newFields = fields
            delete newFields.fk_images;
            setFields(newFields)

            ApiService.post('/save_product_data', { 'id': products[productIndex].id, 'fields': fields }).then((_) => {
                setEdit(false);
                setProductIndex(productIndex + 1);
            }).catch((error) => {
                setAlertMessage(error.message);
            })
        }

        try {
            const newImages = await Utils.toFormData(images);
            let updatedImageArray = images;

            if (newImages && newImages.entries().next().value) {
                let response = await ApiService.postWithFormData('/upload_images', newImages);

                if (response && response.images && response.images.length > 0) {
                    updatedImageArray = await getUpdatedImages(response.images);
                } else {
                    setAlertMessage('Erro ao atualizar produto. Tente novamente.');
                    return;
                }
            }
            await ApiService.post('/update_images', { "product_id": products[productIndex].id, "images": updatedImageArray });
            await saveProduct();
        } catch (error) {
            setErrorMsgSave(error.message);
            return;
        }
    };

    return (
        <Sidebar title={"Mapear Produtos"}>
            <Paper variant="outlined" sx={{ flexDirection: 'column', p: { xs: 2, md: 3 }, textAlign: 'center' }}>
                {(dataSets !== null && fields !== null && productIndex < products.length) ?
                    <React.Fragment>
                        <Box id="product-information">
                            <h3>Produto {productIndex + 1} de {products.length}</h3>
                            <ProductSubtitle title={products[productIndex].title} gtin={products[productIndex].gtin} id="product-title" />
                            <Typography component="h4" variant="h6" align="center" sx={{}}>
                                {Utils.productSubtitle2(
                                    products[productIndex].price,
                                    products[productIndex].stock,
                                    products[productIndex].stock_location,
                                    displaySetting('display_product_cost') === true ? products[productIndex].cost : null
                                ) +
                                    ' | SKU: ' + products[productIndex].id +
                                    ' | ID Externo: ' + products[productIndex].id_dalla +
                                    (products[productIndex].ncm ? ' | NCM: ' + products[productIndex].ncm : '')}
                            </Typography>
                        </Box>
                        <Grid container spacing={2} sx={{ marginBottom: '10px', marginTop: '10px' }} id="product-navigation">
                            <Grid item xs={3} sx={{ textAlign: 'left' }}>
                                <Button variant="contained" onClick={() => setProductIndex(productIndex - 1)} disabled={productIndex === 0}>Anterior</Button>
                            </ Grid>
                            <Grid item xs={6}>
                                <Select variant="outlined" sx={{ height: '40px', width: '100%' }} value={dataIndex}
                                    onChange={(e) => setDataIndex(e.target.value)} disabled={dataSets.length === 1}>
                                    {dataSets !== null ?
                                        dataSets.map((dataSet, index) => {
                                            return <MenuItem key={index} value={index} onClick={() => setDataIndex(index)}>{dataSet.fields.title.value}</MenuItem>
                                        })
                                        : <MenuItem>Carregando...</MenuItem>
                                    }
                                </Select>
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: 'right' }}>
                                <Button variant="contained" onClick={() => setProductIndex(productIndex + 1)} disabled={productIndex === products.length - 1}>Próximo</Button>
                            </Grid>
                        </Grid>

                        {images &&
                            <Grid container justifyContent="center" sx={{ mb: 3, mt: 3 }}>
                                <ImageUploader images={images} setImages={setImages} />
                            </Grid>
                        }

                        <React.Fragment>
                            <Grid container justifyContent="center" >
                                <Grid item xs={12} sx={{ pt: 0, pb: 3, textAlign: 'center' }}>
                                    <DynamicComponent fields={fields} setFields={setFields} step={'summary'} disableSummary={false} />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" >
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="contained" onClick={() => setDialogOpen(true)} sx={{ mt: 3, backgroundColor: 'red' }} >
                                        Excluir Mapeamento
                                    </ Button>
                                    <Button variant="contained" onClick={() => setEdit(true)} sx={{ mt: 3, ml: 1, backgroundColor: 'orange' }} >
                                        Editar Mapeamento
                                    </Button>
                                    <Button variant="contained" onClick={setMapping} sx={{ mt: 3, ml: 1, backgroundColor: 'green' }} >
                                        Aceitar Mapeamento
                                    </Button>
                                </Box>
                            </Grid>
                        </React.Fragment>
                    </React.Fragment>
                    : (products && (products.length === 0 || productIndex >= products.length)) ? <h3>Não há produtos para mapear</h3> : <CenteredLoading />}
            </Paper>
            <ModalAlert alertMessage={alertMessage} setAlertMessage={setAlertMessage} />
            <Dialog open={dialogOpen} onClose={closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Deseja realmente excluir este conjunto de dados?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Esta ação é irreversível.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleDeleteDataset} color="primary" autoFocus>
                        Excluir
                    </Button>
                </DialogActions>
            </ Dialog>
        </Sidebar>
    );
}
